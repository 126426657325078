import React, { Component } from 'react'
import AOS from 'aos'
import '../Style/aos.css'

import { Link } from 'gatsby'

import Header from 'components/header'
import Demo from 'components/s-demo/main'
import Footer from 'components/footer/main'
import SEO from 'components/seo'
import { SectionResultadoBusca, SectionBannerResultado } from '../Style/resultado-busca'

import 'sass/main.scss'

class Site extends Component {
  componentDidMount() {
    AOS.init({
      duration: 1000,
      once: true,
      disable: 'mobile',
    })
  }
  render() {
    return (
      <div className="main">
        <SEO title="Resultado de Busca" />
        <Header />
        <SectionBannerResultado>
          <div className="container">
            <h2>
              <strong>Resultado de Busca para:</strong> lorem ipsum
            </h2>
          </div>
        </SectionBannerResultado>
        <SectionResultadoBusca>
          <div className="container">
            <Link to="/" className="item-resultado">
              <h3>Treinamento com expecialistas</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sed urna vestibulum, dictum metus auctor, hendrerit metus.
                Sed est lorem, laoreet et odio eget, euismod luctus dolor.
              </p>
            </Link>
            <Link to="/" className="item-resultado">
              <h3>Treinamento com expecialistas</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sed urna vestibulum, dictum metus auctor, hendrerit metus.
                Sed est lorem, laoreet et odio eget, euismod luctus dolor.
              </p>
            </Link>
            <Link to="/" className="item-resultado">
              <h3>Treinamento com expecialistas</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sed urna vestibulum, dictum metus auctor, hendrerit metus.
                Sed est lorem, laoreet et odio eget, euismod luctus dolor.
              </p>
            </Link>
            <Link to="/" className="item-resultado">
              <h3>Treinamento com expecialistas</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sed urna vestibulum, dictum metus auctor, hendrerit metus.
                Sed est lorem, laoreet et odio eget, euismod luctus dolor.
              </p>
            </Link>
            <Link to="/" className="item-resultado">
              <h3>Treinamento com expecialistas</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sed urna vestibulum, dictum metus auctor, hendrerit metus.
                Sed est lorem, laoreet et odio eget, euismod luctus dolor.
              </p>
            </Link>
            <Link to="/" className="item-resultado">
              <h3>Treinamento com expecialistas</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sed urna vestibulum, dictum metus auctor, hendrerit metus.
                Sed est lorem, laoreet et odio eget, euismod luctus dolor.
              </p>
            </Link>
          </div>
        </SectionResultadoBusca>
        <Demo />
        <Footer padding_maior={true} />
      </div>
    )
  }
}

export default Site
