import styled from 'styled-components'

export const SectionBannerResultado = styled.section`
  width: 100%;
  height: 108px;
  margin-top: 80px;
  background: linear-gradient(0deg, #02a9ce -22.08%, #0095da 89.23%);
  display: flex;
  align-items: center;
  .container {
    height: 100%;
    display: flex;
    align-items: center;
  }
  h2 {
    font: normal normal 36px/40px 'DepotNew';
    color: #ffffff;
    strong {
      font: normal bold 36px/40px 'DepotNew';
      color: #ffffff;
    }
  }
  @media (max-width: 1050px) {
    margin-top: 60px;
    .container {
      justify-content: center;
    }
    h2 {
      text-align: center;
      font-size: 28px;
      strong {
        display: block;
        text-align: center;
        font-size: 28px;
      }
    }
  }
`

export const SectionResultadoBusca = styled.section`
  padding: 75px 0px;
  .item-resultado {
    display: block;
    padding: 34px;
    background-color: #f7f7f9;
    margin-bottom: 26px;
    &:last-child {
      margin-bottom: 0px;
    }
    h3 {
      font: normal bold 24px/26px 'DepotNew';
      color: #4f5052;
      margin-bottom: 13px;
    }
    p {
      font-size: 16px;
      line-height: 31px;
      color: #4f5052;
      max-width: 933px;
    }
  }
  @media (max-width: 1050px) {
    padding: 40px 0px;
    .item-resultado {
      padding: 30px 20px;
      h3 {
        font-size: 20px;
        line-height: 1;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
`
